import React from 'react'
import useClipboard from 'react-use-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import i18n from '../locales/he.yaml'

const CopyToClipboardButton = ({ link }) => {
  const [isCopied, setCopied] = useClipboard(link, {
    successDuration: 1000
  })
  return (
    <button className='button is-nude' onClick={setCopied}>
      {isCopied ? (
        i18n.ctc.success
      ) : (
        <>
          {i18n.ctc.label} <FontAwesomeIcon icon={faCopy} className='ml-2' />
        </>
      )}
    </button>
  )
}

export default CopyToClipboardButton
