import React, { useState } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGraduationCap,
  faShoePrints,
  faHandsHoldingCircle,
  faCakeCandles,
  faPaintbrush,
  faLightbulb,
  faCalendarDays,
  faCartShopping,
  faLock,
  faBullhorn,
  faTractor,
  faPercent
  // faPersonRunning
} from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import Modal from './Modal'
import Link from './LinkWrapper'
import useAuth from '../hooks/useAuth'

import i18n from '../locales/he.yaml'

const Dashboard = ({ NotInAcademyModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [iframe, setIframe] = useState()
  const {
    isInAcademy,
    sculptureMethodCourse,
    nextChallengeCourse,
    nextZoomMeeting
  } = useAuth()
  const elements = [
    {
      info: i18n.dashboard.courses,
      path: '/dashboard/courses/',
      icon: faGraduationCap,
      isProtected: false
    },
    {
      info: i18n.dashboard.sculptureMethod,
      path: sculptureMethodCourse && sculptureMethodCourse.link,
      icon: faShoePrints,
      isExternal: true,
      isProtected: !sculptureMethodCourse
    },
    {
      info: i18n.dashboard.challenges,
      path: '/dashboard/challenges/',
      icon: faHandsHoldingCircle,
      isProtected: false
    },
    {
      info: i18n.dashboard.sugarDough,
      path: '/dashboard/sugar-dough/',
      icon: faCakeCandles,
      isProtected: false
    },
    {
      info: i18n.dashboard.art,
      path: '/dashboard/art/',
      icon: faPaintbrush,
      isProtected: false
    },
    {
      info: i18n.dashboard.business,
      path: '/dashboard/business/',
      icon: faLightbulb,
      isProtected: false
    }
    // {
    //   info: i18n.dashboard.race,
    //   path: '/dashboard/race/',
    //   icon: faPersonRunning,
    //   isProtected: !isInAcademy
    // }
  ]
  const links = [
    {
      info: i18n.dashboard.whatsappGroup,
      path: 'https://chat.whatsapp.com/Lq7jA3a0u79IgfuFWVK2kT',
      icon: faWhatsapp,
      isExternal: true,
      isNewTab: true,
      isProtected: !isInAcademy
    },
    {
      info: i18n.dashboard.whatsappGroupAnnouncements,
      path: 'https://chat.whatsapp.com/GJBqQdWjCpzEEakVjqX3LU',
      icon: faBullhorn,
      isExternal: true,
      isNewTab: true,
      isProtected: !isInAcademy
    },
    {
      info: i18n.dashboard.whatsappGroupTalks,
      path: 'https://chat.whatsapp.com/CVf36TI42ECJ3uK8R69p5s',
      icon: faTractor,
      isExternal: true,
      isNewTab: true,
      isProtected: !isInAcademy
    },
    {
      info: i18n.dashboard.calendar,
      path: 'https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FJerusalem&showTz=0&showCalendars=0&showPrint=0&showDate=0&hl=iw&src=a2FyaW5uYXJ0c0BrYXJpbm5hcnRzLmNvLmls&color=%23039BE5',
      icon: faCalendarDays,
      iframe: true,
      isProtected: false
    },
    {
      info: i18n.dashboard.discount,
      path: '/academy/courses/',
      icon: faPercent,
      isProtected: false
    },
    {
      info: i18n.dashboard.cookstock,
      path: 'https://www.cookstock.co.il/tags/119384',
      icon: faCartShopping,
      isExternal: true,
      isNewTab: true,
      isProtected: false
    }
  ]
  return (
    <div className='fade-in'>
      {isModalOpen && (
        <NotInAcademyModal onClose={() => setIsModalOpen(false)} />
      )}
      {iframe && (
        <Modal isOpen onClose={() => setIframe()}>
          <div
            style={{ position: 'relative', height: 0, paddingBottom: '100%' }}
          >
            <iframe
              src={iframe}
              style={{
                borderWidth: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'
              }}
              frameBorder='0'
              scrolling='no'
            ></iframe>
          </div>
        </Modal>
      )}
      {!isInAcademy && (
        <div className='hero has-navbar-fixed-top has-background-nude-light'>
          <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
            <div className='container content has-text-centered'>
              <p>{i18n.dashboard.lead.description}</p>
              <Link to='/academy/' className='button is-nude'>
                {i18n.dashboard.lead.callToAction}
              </Link>
            </div>
          </div>
        </div>
      )}
      {/* {isInAcademy && (
        <div className='hero has-navbar-fixed-top has-background-nude-light'>
          <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
            <div className='container content has-text-centered'>
              <p>מפגש קהילה יתקיים ביום שלישי 11/07 באלעד אצל שיינדי ברנט</p>
              <a
                href='https://www.karinnarts.co.il/forms/?ff_landing=31'
                target='_blank'
                rel='noreferrer'
                className='button is-nude'
              >
                הרשמה למפגש הקהילה
              </a>
            </div>
          </div>
        </div>
      )} */}
      {isInAcademy &&
        ((nextZoomMeeting.isShowLink && (
          <div className='hero has-navbar-fixed-top has-background-nude-light'>
            <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
              <div className='container content has-text-centered'>
                <p>
                  {i18n.dashboard.nextZoomMeeting.description}{' '}
                  {nextZoomMeeting.displayDateTime}
                </p>
                <a
                  href={nextZoomMeeting.link}
                  target='_blank'
                  rel='noreferrer'
                  className='button is-nude'
                >
                  {i18n.dashboard.nextZoomMeeting.callToAction}
                </a>
              </div>
            </div>
          </div>
        )) ||
          ((nextChallengeCourse.isRegistered ||
            nextChallengeCourse.isShowRegistration) && (
            <div className='hero has-navbar-fixed-top has-background-nude-light'>
              <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
                <div className='container content has-text-centered'>
                  <p>
                    {nextChallengeCourse.isRegistered
                      ? i18n.dashboard.nextChallenge.link.description
                      : i18n.dashboard.nextChallenge.registration
                          .description}{' '}
                    {nextChallengeCourse.displayDateTime}
                  </p>
                  {nextChallengeCourse.isRegistered &&
                    !nextChallengeCourse.isShowLink && (
                      <p>{i18n.dashboard.nextChallenge.link.hint}</p>
                    )}
                  <p>
                    <a
                      href={
                        nextChallengeCourse.isRegistered &&
                        nextChallengeCourse.isShowLink
                          ? nextChallengeCourse.link
                          : nextChallengeCourse.course.link
                      }
                      target='_blank'
                      rel='noreferrer'
                      className='button is-nude'
                    >
                      {nextChallengeCourse.isRegistered
                        ? nextChallengeCourse.isShowLink
                          ? i18n.dashboard.nextChallenge.link.callToAction
                          : i18n.dashboard.nextChallenge.link.challengeMaterials
                        : i18n.dashboard.nextChallenge.registration
                            .callToAction}
                    </a>
                  </p>
                  {nextChallengeCourse.isRegistered &&
                    nextChallengeCourse.isShowLink && (
                      <a
                        href={nextChallengeCourse.course.link}
                        target='_blank'
                        rel='noreferrer'
                        className='button is-text'
                      >
                        {i18n.dashboard.nextChallenge.link.challengeMaterials}
                      </a>
                    )}
                </div>
              </div>
            </div>
          )))}
      <div className='hero'>
        <div
          className={clsx('hero', {
            'has-navbar-fixed-top':
              isInAcademy &&
              !(
                (
                  nextZoomMeeting.isShowLink ||
                  nextChallengeCourse.isRegistered ||
                  nextChallengeCourse.isShowRegistration
                ) // || true
              )
          })}
        >
          <div className='hero-body'>
            <div className='container'>
              <div className='has-text-centered content is-margin-bottom-6'>
                <h3 className='has-text-nude'>{i18n.dashboard.title}</h3>
              </div>
              <div className='columns is-multiline'>
                {elements.map((element) => {
                  const NewLink = element.isProtected
                    ? ({ children }) => (
                        <button
                          className='button is-nude is-outlined'
                          onClick={() => setIsModalOpen(true)}
                        >
                          <span className='icon'>
                            <FontAwesomeIcon
                              icon={faLock}
                              className='has-text-nude-light'
                            />
                          </span>
                          <span>{children}</span>
                        </button>
                      )
                    : element.isExternal
                    ? ({ children }) => (
                        <a
                          href={element.path}
                          className='button is-nude is-outlined'
                        >
                          {children}
                        </a>
                      )
                    : ({ children }) => (
                        <Link
                          to={element.path}
                          className='button is-nude is-outlined'
                        >
                          {children}
                        </Link>
                      )
                  return (
                    <div
                      key={element.info.title}
                      className='column is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'
                    >
                      <div
                        className='extras-item content is-flex is-flex-direction-column'
                        style={{
                          height: '100%',
                          borderWidth: '2px'
                        }}
                      >
                        <div className='has-text-centered'>
                          <FontAwesomeIcon
                            icon={element.icon}
                            className='is-margin-bottom-4 has-text-nude-light'
                            size='lg'
                          />
                        </div>
                        <h4 className='has-text-centered is-margin-top-4'>
                          {element.info.title}
                        </h4>
                        <p className='is-flex-grow-1'>
                          {element.info.description}
                        </p>
                        <div className='has-text-centered'>
                          <NewLink>{i18n.dashboard.callToAction}</NewLink>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='has-text-centered content is-margin-bottom-6'>
              <h3>{i18n.dashboard.linksTitle}</h3>
            </div>
            <div className='columns is-multiline'>
              {links.map((element) => {
                const NewLink = element.isProtected
                  ? ({ children }) => (
                      <button
                        className='button is-black is-inverted'
                        onClick={() => setIsModalOpen(true)}
                      >
                        <span className='icon'>
                          <FontAwesomeIcon
                            icon={faLock}
                            className='has-text-grey-lighter'
                          />
                        </span>
                        <span>{children}</span>
                      </button>
                    )
                  : element.iframe
                  ? ({ children }) => (
                      <button
                        className='button is-black is-inverted'
                        onClick={() => setIframe(element.path)}
                      >
                        {children}
                      </button>
                    )
                  : element.isExternal
                  ? ({ children }) => (
                      <a
                        href={element.path}
                        className='button is-black is-inverted'
                        target={element.isNewTab ? '_blank' : '_self'}
                        rel='noreferrer'
                      >
                        {children}
                      </a>
                    )
                  : ({ children }) => (
                      <Link
                        to={element.path}
                        className='button is-black is-inverted'
                      >
                        {children}
                      </Link>
                    )
                return (
                  <div
                    key={element.info.title}
                    className='column is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'
                  >
                    <div
                      className='extras-item content has-background-nude-light is-flex is-flex-direction-column'
                      style={{
                        height: '100%'
                      }}
                    >
                      <div className='has-text-centered'>
                        <FontAwesomeIcon
                          icon={element.icon}
                          className='has-text-white is-margin-bottom-4'
                          size='lg'
                        />
                      </div>
                      <h4 className='has-text-centered is-margin-top-4'>
                        {element.info.title}
                      </h4>
                      <p className='is-flex-grow-1'>
                        {element.info.description}
                      </p>
                      <div className='has-text-centered'>
                        <NewLink>{i18n.dashboard.callToAction}</NewLink>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
