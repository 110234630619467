import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGraduationCap,
  faCertificate
} from '@fortawesome/free-solid-svg-icons'
import Link from './LinkWrapper'
import i18n from '../locales/he.yaml'
import useAuth from '../hooks/useAuth'

const Courses = ({ images, location: { pathname } }) => {
  const { myCourses, otherCourses, enrolledCourses } = useAuth()
  return (
    <div className='fade-in'>
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/dashboard/'}>{i18n.header.dashboard}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.dashboard.courses.title}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3 className='has-text-nude'>
              {i18n.dashboard.courses.pageTitle}
            </h3>
            <p>{i18n.dashboard.courses.description}</p>
          </div>
        </div>
      </div>
      {myCourses.length > 0 && (
        <div className='hero'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-multiline'>
                {myCourses.map((course) => {
                  const image = images[course.title]
                  const _courseProgress = enrolledCourses.find(
                    (p) => p.id === course.id
                  )
                  const progress =
                    _courseProgress.steps_total === 0
                      ? '0'
                      : Math.floor(
                          (_courseProgress.steps_completed /
                            _courseProgress.steps_total) *
                            100
                        ).toString()
                  const realCourse = i18n.courses.courses.find(
                    (course) => course.name === course.title
                  )
                  let title = course.title
                  if (realCourse) {
                    title =
                      (realCourse.category === 'דמויות'
                        ? i18n.courses.figureCourseTitlePrefix
                        : i18n.courses.courseTitlePrefix) +
                      ' ' +
                      title
                  }
                  return (
                    <div
                      key={title}
                      className='column is-3-widescreen is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'
                    >
                      <div
                        className='card is-relative is-clickable'
                        style={{ height: '100%' }}
                        onClick={() =>
                          window.open(course.link, '_self', 'rel=noreferrer')
                        }
                      >
                        <div className='course-progress-tag is-margin-top-4'>
                          <span className='tag is-nude is-light'>
                            {
                              i18n.courses.progressStatus[
                                _courseProgress.progress_status
                              ]
                            }
                          </span>
                        </div>
                        <div className='card-image'>
                          {_courseProgress.certificate_link && (
                            <div className='course-certificate-tag is-margin-bottom-4'>
                              <a
                                className='tag is-nude is-light is-link'
                                href={_courseProgress.certificate_link}
                                target='_blank'
                                rel='noreferrer'
                                onClick={(e) => e.stopPropagation()}
                              >
                                {i18n.courses.certificateOpen}
                                <FontAwesomeIcon
                                  icon={faCertificate}
                                  className='is-margin-left-3'
                                />
                              </a>
                            </div>
                          )}
                          <GatsbyImage
                            image={getImage(image)}
                            style={{
                              maxWidth: getImage(image).width,
                              margin: '0 auto' // used to center the image
                            }}
                            alt=''
                          />
                        </div>
                        <div className='card-content'>
                          <div className='content'>
                            <h6 className='course-title'>{title}</h6>
                            <p>
                              <progress
                                className='progress is-nude'
                                value={progress}
                                max='100'
                                style={{
                                  height: '0.25rem',
                                  marginBottom: '0'
                                }}
                              >
                                {progress}%
                              </progress>
                              <span className='help has-text-grey'>
                                % {progress} {i18n.courses.completed}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {otherCourses.length > 0 && (
        <>
          <div className='hero has-background-nude-lighter'>
            <div className='hero-body'>
              <div className='container content has-text-centered'>
                <h3 className='has-text-nude'>
                  {i18n.dashboard.otherCourses.title}
                </h3>
                <p>{i18n.dashboard.otherCourses.description}</p>
              </div>
            </div>
          </div>
          <div className='hero'>
            <div className='hero-body'>
              <div className='container'>
                <div className='columns is-multiline'>
                  {otherCourses.map((course) => {
                    const _courseProgress = enrolledCourses.find(
                      (p) => p.id === course.id
                    )
                    const progress = Math.floor(
                      (_courseProgress.steps_completed /
                        _courseProgress.steps_total) *
                        100
                    ).toString()
                    return (
                      <div
                        key={course.title}
                        className='column is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'
                      >
                        <div
                          className='extras-item content is-flex is-flex-direction-column is-relative is-clickable'
                          style={{
                            height: '100%',
                            borderWidth: '2px'
                          }}
                          onClick={() => {
                            window.open(course.link, '_self', 'rel=noreferrer')
                          }}
                        >
                          <div className='course-progress-tag is-margin-top-4'>
                            <span className='tag is-nude is-light'>
                              {
                                i18n.courses.progressStatus[
                                  _courseProgress.progress_status
                                ]
                              }
                            </span>
                          </div>
                          <div className='has-text-centered'>
                            <FontAwesomeIcon
                              icon={faGraduationCap}
                              className='is-margin-bottom-4 has-text-nude-light'
                              size='lg'
                            />
                          </div>
                          <h4 className='course-title has-text-centered is-margin-top-4'>
                            {course.title}
                          </h4>
                          <div
                            className='is-flex-grow-1 is-margin-bottom-5'
                            dangerouslySetInnerHTML={{
                              __html: course.content
                            }}
                          />
                          <p>
                            <progress
                              className='progress is-nude'
                              value={progress}
                              max='100'
                              style={{
                                height: '0.25rem',
                                marginBottom: '0'
                              }}
                            >
                              {progress}%
                            </progress>
                            <span className='help has-text-grey'>
                              % {progress} {i18n.courses.completed}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Courses
