import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import Modal from './Modal'
import { navigate } from './LinkWrapper'
import i18n from '../locales/he.yaml'

const NotInAcademyModal = ({ onClose }) => (
  <Modal isOpen>
    <div className='box'>
      <div className='is-flex is-flex-direction-column content'>
        <FontAwesomeIcon
          icon={faLock}
          className='is-margin-bottom-4 has-text-nude-light'
          size='lg'
        />
        <p>{i18n.dashboard.protected.description}</p>
        <div className='has-text-centered is-margin-bottom-4 is-margin-top-4'>
          <button
            className='button is-nude'
            onClick={() => {
              onClose(false)
              navigate('/academy/courses/')
            }}
          >
            {i18n.dashboard.protected.goToAcademy}
          </button>
        </div>
        <div className='has-text-centered'>
          <button className='button is-text' onClick={() => onClose(false)}>
            {i18n.dashboard.protected.closeWindow}
          </button>
        </div>
      </div>
    </div>
  </Modal>
)

export default NotInAcademyModal
