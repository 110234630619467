import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUpload,
  faHourglassEnd,
  faSquareCheck
} from '@fortawesome/free-solid-svg-icons'
import { faEye, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import i18n from '../locales/he.yaml'
import useAuth from '../hooks/useAuth'
import Modal from './Modal'
import CopyToClipboardButton from './CopyToClipboardButton'
import Link from './LinkWrapper'
import { MISSIONS, LIVE_MISSIONS } from '../raceConfig'
import { fetchWitRetry } from '../utils'

const PARTICIPANTS_STEPS = [
  [0, '1,000'],
  [10, '1,000'],
  [20, '2,000'],
  [30, '3,000'],
  [40, '4,000'],
  [50, '5,000'],
  [60, '6,000'],
  [70, '7,000'],
  [80, '8,000'],
  [90, '9,000'],
  [100, '10,000']
]

const Race = ({ location: { pathname } }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [isLiveMissionsModalOpen, setIsLiveMissionsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [currentMission, setCurrentMission] = useState('')
  const [currentTask, setCurrentTask] = useState('')
  const [reportLink, setReportLink] = useState('')
  const [reportFile, setReportFile] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [inProgressDelete, setInProgressDelete] = useState(null)
  const [currentMissionReport, setCurrentMissionReport] = useState('all')
  const [selectedUserForAdminReport, setSelectedUserForAdminReport] =
    useState(null)
  const {
    isInAcademy,
    user,
    isAdmin,
    getRaceData,
    addRaceData,
    deleteRaceData,
    race,
    currentUserRaceData
  } = useAuth()
  useEffect(() => {
    const handleVisibilityChange = async function () {
      if (document.visibilityState === 'visible') {
        await getRaceData()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])
  useEffect(() => {
    if (!selectedUser || inProgressDelete) return
    setSelectedUser(race.find((item) => item.id === selectedUser.id))
  }, [inProgressDelete])

  const liveMissionsBottomRef = useCallback((node) => {
    if (node && currentTask) {
      node.scrollIntoView({
        behavior: 'smooth'
      })
    }
  })

  if (!isInAcademy) {
    return
  }

  const isEnableEdit =
    (selectedUser ? selectedUser.id === user.id : false) || isAdmin
  const raceLength = race.length
  return (
    <div className='fade-in'>
      {isVideoModalOpen && (
        <Modal isOpen onClose={() => setIsVideoModalOpen(false)}>
          <div className='box'>
            <div className='content'>
              <h3 className='has-text-centered'>{i18n.dashboard.race.title}</h3>
              <p>{i18n.race.description}</p>
              <h3 className='has-text-centered'>לצפייה בהשקת המשחק</h3>
            </div>
            <figure
              className='image is-16by9'
              style={{ marginRight: 0, marginLeft: 0 }}
            >
              <iframe
                className='has-ratio'
                width='640'
                height='360'
                src={`https://player.vimeo.com/video/838055134?h=826545297f&badge=0&autopause=0player_id=0&app_id=58479`}
                frameBorder='0'
                allow='autoplay; fullscreen;'
              />
            </figure>
            <script src='https://player.vimeo.com/api/player.js'></script>
          </div>
        </Modal>
      )}
      {isLiveMissionsModalOpen && (
        <Modal
          isOpen
          onClose={() => {
            setIsLiveMissionsModalOpen(false)
            setCurrentMission('')
            setCurrentTask('')
            setReportLink('')
            setReportFile('')
            setSelectedUserForAdminReport(null)
          }}
        >
          <div className='box'>
            <div className='is-flex is-flex-direction-column content'>
              {selectedUserForAdminReport && (
                <p className='has-text-centered has-text-weight-bold'>
                  מדווח בשם {selectedUserForAdminReport.name}
                </p>
              )}
              <div className='field'>
                <div className='control'>
                  <div className='select is-fullwidth'>
                    <select
                      id='input-mission'
                      value={currentMission}
                      onChange={(e) => {
                        setCurrentMission(e.target.value)
                        setCurrentTask('')
                        setReportLink('')
                        setReportFile('')
                      }}
                    >
                      <option value='' disabled>
                        {i18n.race.report.chooseMission}
                      </option>
                      {Object.keys(LIVE_MISSIONS).map((key) => (
                        <option key={key} value={key}>
                          #{LIVE_MISSIONS[key].index} -{' '}
                          {LIVE_MISSIONS[key].title}
                          {LIVE_MISSIONS[key].ends
                            ? ` - ${LIVE_MISSIONS[key].ends}`
                            : ''}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {LIVE_MISSIONS[currentMission] && (
                <>
                  <p className='has-text-centered mt-4'>
                    משימה{' '}
                    <span className='has-text-weight-bold'>
                      #{LIVE_MISSIONS[currentMission].index}
                    </span>
                  </p>
                  <p className='is-flex is-align-items-center is-justify-content-center'>
                    <FontAwesomeIcon icon={faHourglassEnd} />
                    <span className='ml-2'>
                      {LIVE_MISSIONS[currentMission].ends ||
                        'פתוחה לכל אורך המשחק'}
                    </span>
                  </p>
                  <h4 className='has-text-centered mb-0'>
                    {LIVE_MISSIONS[currentMission].title}
                  </h4>
                  <div className='content'>
                    <p>{LIVE_MISSIONS[currentMission].description}</p>
                  </div>
                  {LIVE_MISSIONS[currentMission].externalLink && (
                    <>
                      <p>זה הקישור:</p>
                      <p className='has-text-centered'>
                        <a
                          className='button is-text'
                          href={LIVE_MISSIONS[currentMission].externalLink}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {LIVE_MISSIONS[currentMission].externalLink.replace(
                            'https://www.',
                            ''
                          )}
                        </a>
                      </p>
                    </>
                  )}
                  {LIVE_MISSIONS[currentMission].personalLink && (
                    <>
                      <p>זה הקישור האישי והייחודי שלך לשיתוף:</p>
                      <p className='has-text-centered'>
                        <a
                          className='button is-text'
                          href={`${
                            LIVE_MISSIONS[currentMission].personalLink
                          }?ref=${user ? user.id : ''}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {`${LIVE_MISSIONS[
                            currentMission
                          ].personalLink.replace('https://www.', '')}?ref=${
                            user ? user.id : ''
                          }`}
                        </a>
                      </p>
                      <p className='has-text-centered'>
                        <CopyToClipboardButton
                          link={`${
                            LIVE_MISSIONS[currentMission].personalLink
                          }?ref=${user ? user.id : ''}`}
                        />
                      </p>
                    </>
                  )}
                  {Object.keys(LIVE_MISSIONS[currentMission].tasks).length >
                    0 && (
                    <div className='field mt-4'>
                      <label
                        className='label has-text-centered'
                        htmlFor='input-task'
                      >
                        {i18n.race.report.reportTask}
                      </label>
                      <div className='control'>
                        <div className='select is-fullwidth'>
                          <select
                            id='input-task'
                            value={currentTask}
                            onChange={(e) => setCurrentTask(e.target.value)}
                          >
                            <option value='' disabled>
                              {i18n.race.report.chooseTask}
                            </option>
                            {Object.keys(LIVE_MISSIONS[currentMission].tasks)
                              .filter(
                                (key) =>
                                  !LIVE_MISSIONS[currentMission].tasks[key]
                                    .ignore
                              )
                              .map((key) => (
                                <option
                                  key={key}
                                  value={key}
                                  disabled={
                                    currentUserRaceData &&
                                    currentUserRaceData.missions &&
                                    currentUserRaceData.missions.find(
                                      (mission) => mission.id === currentMission
                                    ) &&
                                    currentUserRaceData.missions
                                      .find(
                                        (mission) =>
                                          mission.id === currentMission
                                      )
                                      .tasks.find((task) => task.id === key)
                                  }
                                >
                                  {
                                    LIVE_MISSIONS[currentMission].tasks[key]
                                      .title
                                  }
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {LIVE_MISSIONS[currentMission] &&
                LIVE_MISSIONS[currentMission].tasks[currentTask] && (
                  <>
                    <div className='field'>
                      {(LIVE_MISSIONS[currentMission].tasks[currentTask]
                        .type === 'link' ||
                        LIVE_MISSIONS[currentMission].tasks[currentTask]
                          .type === 'linkOrImage') && (
                        <label className='label' htmlFor='input-type'>
                          {LIVE_MISSIONS[currentMission].tasks[currentTask]
                            .type === 'linkOrImage'
                            ? i18n.race.report.linkWithImage
                            : i18n.race.report.link}
                        </label>
                      )}
                      <div className='control has-text-centered'>
                        {(LIVE_MISSIONS[currentMission].tasks[currentTask]
                          .type === 'link' ||
                          LIVE_MISSIONS[currentMission].tasks[currentTask]
                            .type === 'linkOrImage') && (
                          <input
                            id='input-type'
                            className='input'
                            type='text'
                            value={reportLink}
                            onChange={(e) => setReportLink(e.target.value)}
                          />
                        )}
                        {(LIVE_MISSIONS[currentMission].tasks[currentTask]
                          .type === 'image' ||
                          LIVE_MISSIONS[currentMission].tasks[currentTask]
                            .type === 'linkOrImage') && (
                          <div
                            className={clsx('file is-centered is-boxed', {
                              'has-name': reportFile && reportFile.name,
                              'mt-4':
                                LIVE_MISSIONS[currentMission].tasks[currentTask]
                                  .type === 'linkOrImage'
                            })}
                          >
                            <label className='file-label'>
                              <input
                                className='file-input'
                                type='file'
                                accept='.jpg,.jpeg,.png,.heic'
                                onChange={(event) =>
                                  setReportFile(event.target.files[0])
                                }
                              />
                              <span className='file-cta'>
                                <span className='file-icon'>
                                  <FontAwesomeIcon icon={faUpload} />
                                </span>
                                <span className='file-label'>
                                  {LIVE_MISSIONS[currentMission].tasks[
                                    currentTask
                                  ].type === 'linkOrImage'
                                    ? i18n.race.report.imageWithLink
                                    : i18n.race.report.image}
                                </span>
                              </span>
                              {reportFile && (
                                <span className='file-name'>
                                  {reportFile.name}
                                </span>
                              )}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='field'>
                      <div className='control has-text-centered'>
                        <button
                          className={clsx('button is-nude', {
                            'is-loading': inProgress
                          })}
                          disabled={(!reportLink && !reportFile) || inProgress}
                          onClick={async () => {
                            setInProgress(true)
                            let url = reportLink
                            if (reportFile) {
                              const fileNameParts = reportFile.name.split('.')
                              let extension =
                                fileNameParts.length > 1
                                  ? fileNameParts[fileNameParts.length - 1]
                                  : ''
                              url = `https://s3.eu-central-1.amazonaws.com/race.karinnarts.co.il/${
                                (selectedUserForAdminReport || user).id
                              }/${currentMission}/${currentTask}${
                                extension ? `.${extension}` : ''
                              }`
                              await fetchWitRetry(url, {
                                body: reportFile,
                                mode: 'cors',
                                method: 'PUT',
                                headers: {
                                  'Content-Type': reportFile.type
                                }
                              })
                            }
                            await addRaceData({
                              user_id: selectedUserForAdminReport
                                ? selectedUserForAdminReport.id
                                : undefined,
                              mission_id: currentMission,
                              task_id: currentTask,
                              link: url
                            })
                            setInProgress(false)
                            setCurrentTask('')
                          }}
                        >
                          {i18n.race.report.submit}
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </div>
            <div ref={liveMissionsBottomRef} />
          </div>
        </Modal>
      )}
      {selectedUser && (
        <Modal isOpen onClose={() => setSelectedUser(null)}>
          <div className='box'>
            <div className='table-container'>
              <table className='table is-hoverable is-fullwidth is-size-7'>
                <thead>
                  <tr>
                    <th>משימה</th>
                    <th>סוג דיווח</th>
                    <th>תוצאה</th>
                    <th>סה״כ נקודות</th>
                    {isEnableEdit && <th></th>}
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <th>משימה</th>
                    <th>סוג דיווח</th>
                    <th>תוצאה</th>
                    <th>סה״כ נקודות</th>
                    {isEnableEdit && <th></th>}
                  </tr>
                </tfoot>
                <tbody>
                  {(currentMissionReport === 'all'
                    ? selectedUser.missions
                    : selectedUser.missions.filter(
                        (mission) => mission.id === currentMissionReport
                      )
                  )
                    .map((mission) =>
                      [
                        <tr key={mission.id}>
                          <th style={{ verticalAlign: 'middle' }}>
                            {MISSIONS[mission.id].title}
                          </th>
                          <td></td>
                          <td></td>
                          <th style={{ verticalAlign: 'middle' }}>
                            {mission.points}
                          </th>
                          {isEnableEdit && <td></td>}
                        </tr>
                      ].concat(
                        mission.tasks.map((task) => (
                          <tr key={task.id}>
                            <td></td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {MISSIONS[mission.id].tasks[task.id].title}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {task.link ? (
                                <a
                                  href={task.link}
                                  target='_blank'
                                  className='button is-white has-text-nude is-small'
                                  rel='noreferrer'
                                >
                                  <span className='icon is-small'>
                                    <FontAwesomeIcon icon={faEye} />
                                  </span>
                                </a>
                              ) : (
                                task.count
                              )}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {task.points}
                            </td>
                            {isEnableEdit && (
                              <td style={{ verticalAlign: 'middle' }}>
                                {/* {(LIVE_MISSIONS[mission.id] || isAdmin) && ( */}
                                <button
                                  className={clsx(
                                    'button is-white has-text-nude is-small',
                                    {
                                      'is-loading':
                                        inProgressDelete ===
                                        `${mission.id}:${task.id}`
                                    }
                                  )}
                                  rel='noreferrer'
                                  onClick={async () => {
                                    setInProgressDelete(
                                      `${mission.id}:${task.id}`
                                    )
                                    await deleteRaceData({
                                      user_id: selectedUser.id,
                                      mission_id: mission.id,
                                      task_id: task.id
                                    })
                                    setInProgressDelete(null)
                                  }}
                                >
                                  <span className='icon is-small'>
                                    <FontAwesomeIcon icon={faCircleXmark} />
                                  </span>
                                </button>
                                {/* )} */}
                              </td>
                            )}
                          </tr>
                        ))
                      )
                    )
                    .flat(1)}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/dashboard/'}>{i18n.header.dashboard}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.dashboard.race.title}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3 className='has-text-nude'>{i18n.dashboard.race.title}</h3>
            <p>{i18n.dashboard.race.description}</p>
            <div className='has-text-centered'>
              <button
                className='button has-text-nude is-text'
                onClick={() => setIsVideoModalOpen(true)}
              >
                תיאור המשחק
              </button>
            </div>
            <div className='buttons is-centered mt-5'>
              <button
                className='button is-nude'
                onClick={() => setIsLiveMissionsModalOpen(true)}
              >
                {i18n.race.liveMissions}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='content has-text-centered mb-6'>
              <h3>מספר המשתתפות וגודל הפרס</h3>
            </div>
            <ul className='steps is-centered is-thin has-gaps has-content-centered is-short'>
              {PARTICIPANTS_STEPS.map((participants, index) => (
                <li
                  key={participants[0]}
                  className={clsx('steps-segment', {
                    'is-active':
                      participants[0] > raceLength &&
                      (PARTICIPANTS_STEPS[index - 1] &&
                        PARTICIPANTS_STEPS[index - 1][0]) <= raceLength,
                    'is-dashed': raceLength < participants[0]
                  })}
                >
                  {raceLength >= participants[0] &&
                    raceLength <
                      (PARTICIPANTS_STEPS[index + 1] &&
                        PARTICIPANTS_STEPS[index + 1][0]) && (
                      <div className='extra-data'>
                        <span className='tag is-nude is-rounded'>
                          {raceLength}
                        </span>
                      </div>
                    )}
                  <span
                    className={clsx('steps-marker', {
                      'is-hollow': raceLength < participants[0],
                      'is-nude': raceLength >= participants[0]
                    })}
                  ></span>
                  <div className='steps-content'>
                    <p className='has-text-centered'>{participants[0]}</p>
                    <p className='is-size-6'>
                      <span
                        className={clsx('tag is-nude', {
                          'is-light': !(raceLength >= participants[0])
                        })}
                      >
                        <span className='has-text-centered'>
                          {participants[1]}₪
                        </span>
                      </span>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='content has-text-centered'>
              <h3>ניקוד המשתתפות</h3>
              <div className='field'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={currentMissionReport}
                      onChange={(e) => {
                        setCurrentMissionReport(e.target.value)
                      }}
                    >
                      <option value='all'>כל המשימות</option>
                      {Object.keys(MISSIONS).map((key) => (
                        <option key={key} value={key}>
                          משימה #{MISSIONS[key].index} - {MISSIONS[key].title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='columns is-centered is-vcentered mt-4'>
              <div className='column is-full-real-mobile is-12-tablet is-10-desktop is-8-widescreen'>
                <table className='table is-hoverable is-fullwidth'>
                  <thead>
                    <tr>
                      <th>
                        <abbr>מקום</abbr>
                      </th>
                      <th>שם</th>
                      <th>פרס</th>
                      <th>סה״כ נקודות</th>
                      <th></th>
                      {isAdmin && <th></th>}
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>
                        <abbr>מקום</abbr>
                      </th>
                      <th>שם</th>
                      <th>פרס</th>
                      <th>סה״כ נקודות</th>
                      <th></th>
                      {isAdmin && <th></th>}
                    </tr>
                  </tfoot>
                  <tbody>
                    {(currentMissionReport === 'all'
                      ? race
                      : race
                          .filter((item) =>
                            item.missions.find(
                              (mission) => mission.id === currentMissionReport
                            )
                          )
                          .map((item) => ({
                            ...item,
                            points: item.missions.find(
                              (mission) => mission.id === currentMissionReport
                            ).points
                          }))
                          .sort((item1, item2) => item2.points - item1.points)
                    ).map((item, index) => (
                      <tr key={item.id}>
                        <th style={{ verticalAlign: 'middle' }}>{index + 1}</th>
                        <td style={{ verticalAlign: 'middle' }}>{item.name}</td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {item.award}₪
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          {item.points}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <button
                            className='button is-white has-text-nude is-small'
                            onClick={() => setSelectedUser(item)}
                          >
                            <span className='icon is-small'>
                              <FontAwesomeIcon icon={faEye} />
                            </span>
                          </button>
                        </td>
                        {isAdmin && (
                          <td style={{ verticalAlign: 'middle' }}>
                            <button
                              className={clsx(
                                'button is-white has-text-nude is-small'
                              )}
                              rel='noreferrer'
                              onClick={() => {
                                setSelectedUserForAdminReport(item)
                                setIsLiveMissionsModalOpen(true)
                                if (currentMissionReport !== 'all') {
                                  setCurrentMission(currentMissionReport)
                                }
                              }}
                            >
                              <span className='icon is-small'>
                                <FontAwesomeIcon icon={faSquareCheck} />
                              </span>
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Race
