import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import AuthCode from 'react-auth-code-input'
import useAuth from '../hooks/useAuth'
import i18n from '../locales/he.yaml'
import Link from './LinkWrapper'

const LoginForm = () => {
  const [input, setInput] = useState('')
  const [countdown, setCountdown] = useState(0)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState(null)
  const [isCodeSent, setIsCodeSent] = useState(false)
  const ac = useRef(null)
  const { login, authenticate } = useAuth()
  const handleOnChange = async (code) => {
    setError(null)
    if (code.length === 6) {
      setError(null)
      setInProgress(true)
      if (ac.current) ac.current.abort()
      const response = await login({ term: input.toLowerCase(), code })
      if (response.isSuccess) {
        await authenticate()
      } else if (response.errors && response.errors.wrongCode) {
        setError({ wrongCode: true })
      } else {
        setError({ general: true })
      }
      setInProgress(false)
    }
  }
  useEffect(() => {
    if (!isCodeSent) return
    ac.current = AbortController ? new AbortController() : null
    return () => {
      if (ac.current) ac.current.abort()
      ac.current = null
    }
  }, [isCodeSent])
  useEffect(() => {
    async function callMe() {
      if (!isCodeSent || !('OTPCredential' in window)) return

      try {
        const { code } = await navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: ac.current ? ac.current.signal : null
        })
        await handleOnChange(code)
      } catch (e) {
        void 0
      }
    }
    callMe()
  }, [isCodeSent])
  return (
    <div className='card'>
      <header className='card-header direction-column items-center'>
        <h3 className='card-header-title is-centered is-size-4'>
          {i18n.login.title}
        </h3>
      </header>
      <div className='card-content'>
        <>
          {!isCodeSent && (
            <>
              <div className='field'>
                <label className='label mt-6' htmlFor='login-email'>
                  {i18n.login.inputLabel}
                </label>
                <div className='control'>
                  <input
                    id='login-email'
                    className={clsx('input', {
                      'is-danger':
                        error && (error.wrongFormat || error.noSuchUser)
                    })}
                    dir='ltr'
                    type='text'
                    value={input}
                    onChange={(e) => {
                      setError(null)
                      setInput(e.target.value)
                    }}
                    disabled={inProgress || isCodeSent}
                    required
                  />
                </div>
                {error && (error.wrongFormat || error.noSuchUser) && (
                  <p className='help is-danger is-absolute'>
                    {error.wrongFormat
                      ? i18n.login.errors.wrongFormat
                      : i18n.login.errors.noSuchUser}
                  </p>
                )}
              </div>
              <div className='has-text-centered mt-6'>
                <button
                  className={clsx('button is-nude is-medium', {
                    'is-loading': inProgress
                  })}
                  type='submit'
                  disabled={inProgress}
                  onClick={async () => {
                    setError(null)

                    const isEmail = new RegExp(
                      /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
                    ).test(input)
                    const isPhone = new RegExp(/^\d{10}$/).test(input)

                    if (!isEmail && !isPhone) {
                      setError({ wrongFormat: true })
                      return
                    }

                    setInProgress(true)
                    const response = await login({ term: input.toLowerCase() })
                    if (response.isSuccess) {
                      setIsCodeSent(true)
                      setCountdown(Date.now() + 120000)
                    } else if (response.errors && response.errors.noSuchUser) {
                      setError({ noSuchUser: true })
                    } else {
                      setError({ general: true })
                    }
                    setInProgress(false)
                  }}
                >
                  {i18n.login.codeCta}
                </button>
              </div>
            </>
          )}
          {isCodeSent && (
            <>
              <div className='field'>
                <label className='label has-text-centered has-text-weight-normal'>
                  {i18n.login.codeLabel + ' ' + input}
                </label>
                <Countdown
                  key='countdown'
                  date={countdown}
                  onComplete={() => {
                    setError(null)
                    setIsCodeSent(false)
                  }}
                  renderer={({ formatted: { minutes, seconds } }) => (
                    <p className='has-text-centered has-text-weight-bold mb-6'>
                      {i18n.login.codeCountdown} {minutes}:{seconds}
                    </p>
                  )}
                />
                <div dir='ltr'>
                  <AuthCode
                    allowedCharacters='numeric'
                    onChange={handleOnChange}
                    containerClassName='is-justify-content-center otp-input-container'
                    inputClassName={clsx('input otp-input has-text-centered', {
                      'is-danger': error && error.wrongCode
                    })}
                    disabled={inProgress}
                  />
                </div>
                {error && error.wrongCode && (
                  <p
                    className='help is-danger is-absolute'
                    style={{ left: '50%', transform: 'translate(-50%, 0)' }}
                  >
                    {i18n.login.errors.wrongCode}
                  </p>
                )}
              </div>
              <div className='has-text-centered mt-6'>
                <button
                  className={clsx('button is-nude is-medium', {
                    'is-loading': inProgress
                  })}
                  type='submit'
                  disabled={!inProgress}
                >
                  {i18n.login.cta}
                </button>
              </div>
            </>
          )}
          {error && error.general && (
            <div className='field mt-6'>
              <p className='help is-danger has-text-centered'>
                {i18n.login.errors.general}
              </p>
            </div>
          )}
          {error && error.noSuchUser && (
            <div className='field mt-6'>
              <p className='content'>{i18n.login.invite}</p>
              <div className='has-text-centered'>
                <Link to='/academy/' className='button is-nude is-light'>
                  {i18n.login.inviteCta}
                </Link>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  )
}
export default LoginForm
