import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faLock } from '@fortawesome/free-solid-svg-icons'
import Link from './LinkWrapper'
import i18n from '../locales/he.yaml'
import useAuth from '../hooks/useAuth'

const Business = ({ location: { pathname }, NotInAcademyModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { businessCourses, enrolledCourses } = useAuth()
  return (
    <div className='fade-in'>
      {isModalOpen && (
        <NotInAcademyModal onClose={() => setIsModalOpen(false)} />
      )}
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/dashboard/'}>{i18n.header.dashboard}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.dashboard.business.title}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3 className='has-text-nude'>{i18n.dashboard.business.title}</h3>
            <p>{i18n.dashboard.business.description}</p>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-multiline'>
              {businessCourses.map((course) => {
                const _courseProgress = enrolledCourses.find(
                  (p) => p.id === course.id
                )
                const isEnrolledToCourse = !!_courseProgress
                const progress = isEnrolledToCourse
                  ? Math.floor(
                      (_courseProgress.steps_completed /
                        _courseProgress.steps_total) *
                        100
                    ).toString()
                  : '0'
                return (
                  <div
                    key={course.title}
                    className='column is-4-desktop is-6-tablet is-10-mobile is-offset-1-mobile'
                  >
                    <div
                      className='extras-item content is-flex is-flex-direction-column is-relative is-clickable'
                      style={{
                        height: '100%',
                        borderWidth: '2px'
                      }}
                      onClick={() => {
                        if (!isEnrolledToCourse) {
                          setIsModalOpen(true)
                          return
                        }
                        window.open(course.link, '_self', 'rel=noreferrer')
                      }}
                    >
                      {isEnrolledToCourse && (
                        <div className='course-progress-tag is-margin-top-4'>
                          <span className='tag is-nude is-light'>
                            {
                              i18n.courses.progressStatus[
                                _courseProgress.progress_status
                              ]
                            }
                          </span>
                        </div>
                      )}
                      {!isEnrolledToCourse && (
                        <div className='course-locked-tag is-margin-top-4'>
                          <span className='tag is-nude is-light'>
                            <FontAwesomeIcon
                              icon={faLock}
                              className='has-text-nude'
                            />
                          </span>
                        </div>
                      )}
                      <div className='has-text-centered'>
                        <FontAwesomeIcon
                          icon={faLightbulb}
                          className='is-margin-bottom-4 has-text-nude-light'
                          size='lg'
                        />
                      </div>
                      <h4 className='course-title has-text-centered is-margin-top-4'>
                        {course.title}
                      </h4>
                      <div
                        className='is-flex-grow-1 is-margin-bottom-5'
                        dangerouslySetInnerHTML={{
                          __html: course.content
                        }}
                      />
                      <p>
                        <progress
                          className='progress is-nude'
                          value={progress}
                          max='100'
                          style={{
                            height: '0.25rem',
                            marginBottom: '0'
                          }}
                        >
                          {progress}%
                        </progress>
                        <span className='help has-text-grey'>
                          % {progress} {i18n.courses.completed}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Business
